import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Volley discovers the possibility of voice through AI-driven games.`}</h2>
    <h2><figure className="wp-caption"><figcaption className="wp-caption-text">{`Founders of Volley: CTO James Wilsterman (left) and CEO Max Child (right)`}</figcaption></figure><strong parentName="h2">{`SUMMARY`}</strong></h2>
    <p>{`Volley, founded by Max Child and James Wilsterman, is a company that builds voice-interactive, AI-driven games and entertainment on voice interfaces, namely Amazon Alexa and Google Home. Max, CEO `}{`&`}{` co-founder of Volley, shares how they built some of the most engaging and highly reviewed voice games on Amazon Alexa including `}<a parentName="p" {...{
        "href": "https://vpdg.app.link/gRrrNtfLOG"
      }}>{`Yes Sire`}</a>{` and `}<a parentName="p" {...{
        "href": "https://xtjl.app.link/pVKFWTuLOG"
      }}>{`Song Quiz`}</a>{` (ranked #1 and #3, respectively, on the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/s/ref=lp_14284827011_st?rh=n%3A13727921011%2Cn%3A%2113727922011%2Cn%3A14284827011&qid=1506632859&sort=smooth-review-rank"
      }}>{`Alexa Skill Store`}</a>{`).`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/10/06173101/5c5ec-1o-ayakc0x8ej6zhdt2hppa.png",
        "alt": null
      }}></img>{`Yes Sire by Volley`}</p>
    <h2><strong parentName="h2">{`CHALLENGES`}</strong></h2>
    <p>{`Conversational interaction is unlike any other kind of technology engagement. Brands now have an uninterrupted, direct line to consumers through devices like Amazon Alexa and Google Home. Traditional web and mobile analytics platforms do not have the capabilities to capture, process, and measure this new form of interaction.`}</p>
    <p>{`Volley was looking for a specific conversational analytics solution that displayed the back and forth interaction between the user and the voice assistant. Until they found `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=casestudy&utm_content=volley"
      }}>{`Dashbot`}</a>{`, there were no analytics companies that could really capture what users do at different key points in the interaction and what caused them to leave the conversation.`}</p>
    <h2><strong parentName="h2">{`HOW DASHBOT HELPED`}</strong></h2>
    <p>{`Dashbot’s Live Transcripts enable insight into real-time conversations occurring between users and bots. According to Max, Live Transcripts enable Volley to monitor their user experience and debug any issues in real-time.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/10/06173101/3a437-1fvcx2w6-fabzetvctxwdhw.png",
        "alt": null
      }}></img></p>
    <p>{`Example of Live Transcript for Alexa Skills`}</p>
    <p>{`For example, Volley experienced an unexpected spike in traffic which caused some stability issues. They used Live Transcripts to debug the issue and to see if it was affecting many of their users. As a result, they increased database limits and were able to scale their Skill to support a greater audience.`}</p>
    <p>{`In addition to the benefits of Live Transcripts, Max remarks, “We chose Dashbot because of the pace of product improvement. We wanted an analytics solution that would grow as fast as we are growing and a team that would be able to turn around our feedback quickly.”`}</p>
    <h2><strong parentName="h2">{`THE RESULTS`}</strong></h2>
    <p>{`With Dashbot’s analytics, Volley gleaned insights that informed product changes as well as bolstered pitch decks for investor meetings.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/10/06173100/20a07-1ivdcux_bgemtqk_pb_2aza.png",
        "alt": null
      }}></img></p>
    <p>{`Song Quiz by Volley`}</p>
    <p>{`For `}<a parentName="p" {...{
        "href": "https://xtjl.app.link/pVKFWTuLOG"
      }}>{`Song Quiz`}</a>{`, a music trivia Alexa Skill, Volley expanded music genres and added multi-user features which doubled their retention and increased engagement by 80%. Volley ran feature experiments and used Dashbot to measure the results of these product improvements.`}</p>
    <p>{`Not only were conversation-specific analytics helpful with their product decisions, Dashbot was also instrumental in Volley’s fundraising efforts. Max and James encountered investors in the voice industry who asked for specific metrics that only Dashbot could provide. Examples include retention, engagement per user, and additional stats regarding power users.`}</p>
    <p>{`Enable their Skills on your Alexa devices and get addicted to their games: `}<a parentName="p" {...{
        "href": "https://vpdg.app.link/gRrrNtfLOG"
      }}>{`Yes Sire`}</a>{` and `}<a parentName="p" {...{
        "href": "https://xtjl.app.link/pVKFWTuLOG"
      }}>{`Song Quiz`}</a>{`.`}</p>
    <hr></hr>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=casestudy&utm_content=volley"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</em></p>
    <p><em parentName="p">{`We natively support Facebook Messenger, Twitter, Amazon Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface. Sign up for free at `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=casestudy&utm_content=volley"
        }}>{`www.dashbot.io`}</a>{`.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      